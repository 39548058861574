import React, { useState } from "react"
import "./blog.module.scss"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Link from "gatsby-link"
import Img from "gatsby-image"
import { graphql } from "gatsby"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import Container from "react-bootstrap/Container"
import CTA from "../components/cta"
import BlogPreview from "../components/blog-preview.js"

const arrayChunks = (array, chunk_size = 3) =>
  Array(Math.ceil(array.length / chunk_size))
    .fill()
    .map((_, index) => index * chunk_size)
    .map(begin => array.slice(begin, begin + chunk_size))
const formatTag = tags =>
  (tags &&
    tags.map(tag =>
      tag
        .split(" ")
        .join("-")
        .toLowerCase()
    ))[0] || null

const ColumnProps = {
  xs: 12,
  sm: 6,
  md: 4,
  // lg: 4
}

const BlogIndex = ({ data }) => {
  const [filter, setFilter] = useState()

  const allPosts =
    (data && data.allContentfulBlogPost && data.allContentfulBlogPost.edges) ||
    []
  const highlighted =
    data &&
    data.highligtedBlogPost &&
    data.highligtedBlogPost.edges &&
    data.highligtedBlogPost.edges[0] &&
    data.highligtedBlogPost.edges[0].node
  const featured =
    data &&
    data.featuredBlogPost &&
    data.featuredBlogPost.edges &&
    data.featuredBlogPost.edges

  const allTags = allPosts.map(({ node }) => node.tags && node.tags[0])
  const tags = allTags
    .filter((val, idx, array) => array.indexOf(val) === idx)
    .map(tag => [formatTag([tag]), tag])

  const posts =
    (!filter && allPosts) ||
    allPosts.filter(({ node }) => node.tags && formatTag(node.tags) === filter)

  let chunkedPosts = arrayChunks(posts, 3)

  const handleFilterChange = ev => {
    const value = ev && ev.target && ev.target.value
    setFilter(value)
  }

  return (
    <Layout>
      <SEO title="Blog" />
      <div styleName="wrapper">
        {highlighted && (
          <div styleName="hero">
            <Img
              styleName="image"
              loading="eager"
              fluid={highlighted.heroImage.fluid}
            />{" "}
            <div styleName="content">
              <div className="blog-tag">
                {highlighted.tags && highlighted.tags[0]}
              </div>
              <h1> {highlighted.title} </h1>
              <small>{highlighted.publishDate}</small>
              <div
                dangerouslySetInnerHTML={{
                  __html: highlighted.description.childMarkdownRemark.html,
                }}
              />
              <Link styleName="hero-read-more" to={`/blog/${highlighted.slug}`}>
                Continue Reading &rarr;
              </Link>
            </div>
          </div>
        )}
        <Container styleName="featured">
          <h2 styleName="section-headline">Featured</h2>
          <Row>
            {featured.map(({ node }) => {
              return (
                <Col {...ColumnProps} key={node.slug} styleName="post-wrapper">
                  <BlogPreview post={node} />
                </Col>
              )
            })}
          </Row>
        </Container>
        <Container styleName="posts">
          <div styleName="section-headline--filter">
            {/*<h2>All posts</h2>*/}
            <div styleName="filter">
              <label htmlFor="tag-filter">Filter by</label>
              <select
                id="tag-filter"
                name="tag-filter"
                onChange={handleFilterChange}
                defaultValue=""
              >
                <option value=""> All </option>
                {tags &&
                  tags.map(([id, value]) => (
                    <option key={id} value={id}>
                      {" "}
                      {value}{" "}
                    </option>
                  ))}
              </select>
            </div>
          </div>
          {chunkedPosts.map((chunk, idx) => (
            <Row key={idx}>
              {chunk.map(({ node }) => (
                <Col {...ColumnProps} key={node.slug} styleName="post-wrapper">
                  <BlogPreview post={node} />
                </Col>
              ))}
            </Row>
          ))}
        </Container>
      </div>
      <CTA isFreeReport={true} />
    </Layout>
  )
}

export default BlogIndex

export const pageQuery = graphql`
  query BlogIndexQuery {
    highligtedBlogPost: allContentfulBlogPost(
      sort: { fields: [publishDate], order: [DESC] }
      limit: 1
      filter: { isHighlight: { eq: true }, node_locale: { eq: "en-US" } }
    ) {
      edges {
        node {
          ...BlogPreviewFields
          description {
            childMarkdownRemark {
              html
            }
          }
          heroImage {
            fluid(maxWidth: 900, maxHeight: 570, quality: 95) {
              ...GatsbyContentfulFluid_withWebp
            }
          }
        }
      }
    }
    featuredBlogPost: allContentfulBlogPost(
      sort: { fields: [publishDate], order: [DESC] }
      limit: 3
      filter: { isFeatured: { eq: true }, node_locale: { eq: "en-US" } }
    ) {
      edges {
        node {
          ...BlogPreviewFields
        }
      }
    }
    allContentfulBlogPost(
      sort: { fields: [publishDate], order: DESC }
      filter: { node_locale: { eq: "en-US" } }
    ) {
      edges {
        node {
          ...BlogPreviewFields
        }
      }
    }
  }
`

export const BlogPreviewFields = graphql`
  fragment BlogPreviewFields on ContentfulBlogPost {
    title
    subtitle
    slug
    publishDate(formatString: "MMMM D, YYYY")
    tags
    type
    heroImage {
      sizes(maxWidth: 380, maxHeight: 120, quality: 100) {
        ...GatsbyContentfulSizes_withWebp
      }
    }
  }
`
