import React from "react"
import "./blog-preview.module.scss"
import Link from "gatsby-link"
import Img from "gatsby-image"
import TruncateMarkup from "react-truncate-markup"

import Card from "react-bootstrap/Card"

const BlogPreview = ({ post, ...otherProps }) => {
  const explorersMap = tag => {
    let module = "random"
    Object.entries(explorersModule).map(([key, value]) => {
      if (value.find(y => y.toLowerCase() === tag.toLowerCase())) {
        module = key
      }
    })
    return module
  }
  const explorersModule = {
    aboutyou: [
      "personal work ethic",
      "physical health",
      "psychological health",
      "factors outside work",
    ],
    thefacilities: ["Physical Work Environment", "Soft Work Environment"],
    thecompany: ["Business Fundamentals", "Corporate Culture"],
    yournetwork: [
      "Management Style",
      "Job Satisfaction",
      "Employee Engagement",
      "Employee Commitment",
    ],
  }
  return (
    <Link to={`/blog/${post.slug}`} className="card" styleName="wrapper">
      {post.heroImage && post.heroImage.sizes && (
        <Img
          className="card-img-top"
          // styleName="img"
          title={post.title}
          sizes={post.heroImage.sizes}
        />
      )}
      <Card.Body styleName="body">
        {post.tags && post.tags.length > 0 && (
          <div styleName="card-top-row">
            <div
              styleName={`tag ${explorersMap(post.tags[0])}`}
              className="blog-tag"
            >
              {post.tags[0]}
            </div>
            <div styleName="published-date">{post.publishDate}</div>
          </div>
        )}
        <Card.Title styleName="title">
          <TruncateMarkup lines={2}>
            <h5>{post.title}</h5>
          </TruncateMarkup>
        </Card.Title>
        <p styleName="subtitle">
          <TruncateMarkup lines={2}>
            <span>{post.subtitle}</span>
          </TruncateMarkup>
        </p>
      </Card.Body>
    </Link>
  )
}

export default BlogPreview
